import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { alpha, useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate, useLocation } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from "../../../../firebase";
import {
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import { Logout, PersonAdd } from "@mui/icons-material";

// import { NavItem } from './components';

// const Topbar = ({ onSidebarOpen, pages, colorInvert = false }) => {
const Topbar = ({ onSidebarOpen, colorInvert = false }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { mode } = theme.palette;
  const [user, setUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);
  const open = Boolean(anchorEl);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        db.collection("usersCompany")
          .doc(user.uid)
          .onSnapshot((doc) => {
            const companyUID = doc.data().companyUID;
            db.collection("companyDB")
              .doc(companyUID)
              .collection("userProfiles")
              .doc(user.uid)
              .get()
              .then((doc) => {
                setUser(doc.data());
                setLoading(false);
              });
          });
      } else {
        setLoading(false);
      }
    });
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // const {
  //   landings: landingPages,
  //   secondary: secondaryPages,
  //   company: companyPages,
  //   account: accountPages,
  //   portfolio: portfolioPages,
  //   blog: blogPages,
  // } = pages;

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={1}
      py={2}
    >
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        component="a"
        onClick={() => navigate("/")}
        title="Partix"
        sx={{
          cursor: "pointer",
        }}
        width={{ xs: 80, md: 110 }}
      >
        <Box
          component={"img"}
          src={
            mode === "light" && !colorInvert
              ? "/partix-Black.svg"
              : "/partix-White.svg"
          }
          height={1}
          width={1}
        />
      </Box>

      <Box sx={{ display: { xs: "none", md: "flex" } }} alignItems={"center"}>
        <Box>
          {/* <NavItem
            title={'Landings'}
            id={'landing-pages'}
            items={landingPages}
            colorInvert={colorInvert}
          /> */}

          <Button
            //variant="outlined"
            color="primary"
            component="a"
            // href="https://mui.com/store/items/the-front-landing-page/"
            onClick={() => {
              navigate("/contactus");
            }}
            size="large"
          >
            Contact Us
          </Button>
        </Box>
        {/* <Box marginLeft={4}>
          <NavItem
            title={'Company'}
            id={'company-pages'}
            items={companyPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'Account'}
            id={'account-pages'}
            items={accountPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'Pages'}
            id={'secondary-pages'}
            items={secondaryPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'Blog'}
            id={'blog-pages'}
            items={blogPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'Portfolio'}
            id={'portfolio-pages'}
            items={portfolioPages}
            colorInvert={colorInvert}
          />
        </Box> */}
        <Box marginLeft={4}>
          <Button
            //variant="outlined"
            color="primary"
            component="a"
            // href="https://mui.com/store/items/the-front-landing-page/"
            onClick={() => {
              navigate("/pricing");
            }}
            size="large"
          >
            Pricing
          </Button>
        </Box>
        {!loading && (
          <Box marginLeft={4}>
            {user ? (
              // <Button
              //   variant="contained"
              //   color="primary"
              //   component="a"
              //   // href="https://mui.com/store/items/the-front-landing-page/"
              //   onClick={() => {
              //     signOut(auth);
              //     setUser(null);
              //   }}
              //   size="large"
              // >
              //   Sign Out
              // </Button>
              <IconButton size="small" onClick={handleClick}>
                {user?.photoURL ? (
                  <Avatar src={user?.photoURL} />
                ) : (
                  <Avatar
                    sx={{ width: 32, height: 32, p: 3, bgcolor: "#377DFF" }}
                  >
                    {user?.firstName[0] + user?.lastName[0]}
                  </Avatar>
                )}
              </IconButton>
            ) : (
              location.pathname !== "/superadminsignup" &&
              location.pathname !== "/usersignup" &&
              location.pathname !== "/companysignup" && (
                <Button
                  variant="contained"
                  color="primary"
                  component="a"
                  // href="https://mui.com/store/items/the-front-landing-page/"
                  onClick={() => {
                    navigate("/usersignin");
                  }}
                  size="large"
                >
                  Sign In
                </Button>
              )
            )}
          </Box>
        )}
      </Box>
      <Box sx={{ display: { xs: "flex", md: "none" } , gap : "10px" }} alignItems={"center"}>
        {!loading && (
          <Box>
            {user ? (
              // <Button
              //   variant="contained"
              //   color="primary"
              //   component="a"
              //   // href="https://mui.com/store/items/the-front-landing-page/"
              //   onClick={() => {
              //     signOut(auth);
              //     setUser(null);
              //   }}
              //   size="large"
              // >
              //   Sign Out
              // </Button>
              <IconButton size="small" onClick={handleClick}>
                {user?.photoURL ? (
                  <Avatar src={user?.photoURL} />
                ) : (
                  <Avatar sx={{ width: 32, height: 32, bgcolor: "#377DFF" }}>
                    {user?.firstName[0] + user?.lastName[0]}
                  </Avatar>
                )}
              </IconButton>
            ) : (
              location.pathname !== "/superadminsignup" &&
              location.pathname !== "/usersignup" &&
              location.pathname !== "/companysignup" && (
                <Button
                  variant="contained"
                  color="primary"
                  component="a"
                  // href="https://mui.com/store/items/the-front-landing-page/"
                  onClick={() => {
                    navigate("/usersignin");
                  }}
                  size="large"
                >
                  Sign In
                </Button>
              )
            )}
          </Box>
        )}
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={"outlined"}
          sx={{
            borderRadius: 2,
            minWidth: "auto",
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            navigate("/account");
          }}
        >
          View Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            signOut(auth);
            setUser(null);
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
};

export default Topbar;
